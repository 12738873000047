import mnplogo from './mnplogo.jpg';
import mawiwlogo from './mawiwlogo.png';

import './App.css';

function App() {
  return (
    <div className="mnp-scholarship-page">
      <header>
        <div className="image-banner">
          <img src={mnplogo} className="mnp-logo logo" alt="MNPP logo" />
          <img src={mawiwlogo} className="mawiw-logo logo" alt="MAWIW logo" />
        </div>
        <div style={{width: '100%', marginBottom: 60}}>
          <h1>
            <span style={{display: 'block'}}>Maritimes & Northeast Pipeline</span>
            <span style={{display: 'block'}}>New Brunswick First Nation</span>
            <span style={{display: 'block'}}>Academic Awards</span>
          </h1>
        </div>
      </header>
      <div className="mnp-scholarship-description">
        <p>
          Through financial contributions provided by Maritimes & Northeast
          Pipeline, awards will be given to successfully selected, qualifying students to
          recognize academic achievement. A limited number of $500.00 Academic
          Awards will be presented to qualifying high school students and a limited
          number of $1,000.00 Academic Awards will be presented to qualifying post-
          secondary students. The New Brunswick First Nations Academic Awards
          have been developed in partnership between the MAWIW Council Inc., the
          First Nations of New Brunswick and Maritimes & Northeast Pipeline.
        </p>

        <h2>Who is eligible?</h2>


          <h3>Maliseet and Mi’kmaq students who are a registered member of their New Brunswick First Nation:</h3>

          <h4>MAWIW Inc.:</h4>
          <ul>
            <li>Elsipogotog</li>
            <li>Esgenoopetitj</li>
            <li>Neqotokuk (Tobique)</li>
          </ul>

          <h4>New Brunswick First Nations:</h4>
          <ul>
            <li>Buctouche</li>
            <li>Natoaganeg (Eel Ground)</li>
            <li>Ugpi'ganjig (Eel River Bar)</li>
            <li>Fort Folly</li>
            <li>Indian Island</li>
            <li>Kingsclear</li>
            <li>Madawaska</li>
            <li>Metepenagiag</li>
            <li>Welamukotuk (Oromocto)</li>
            <li>Pabineau</li>
            <li>Sitansisk (St. Mary's)</li>
            <li>Woodstock</li>
          </ul>

          <h3>High School students</h3>
          <p>Enrolled in High School full time and graduating high school in June 2022.</p>

          <h3>Post-Secondary Students</h3>
          <p>Enrolled in a minimum two year, full time (four or more courses, 12 or more credit hours, per term) university, college, vocational or technical institute in the current academic year, 2021-2022.</p>

          <p>All applications are welcome from qualifying students.</p>
          <p>Only successful applicants will be notified via email.</p>

          <h4>The last day to apply is July 15, 2022. The last day we accept transcripts is July 29, 2022.</h4>

          <p>If you have any questions, please email <a href="mailto:support@mnpawards.ca">support@mnpawards.ca</a></p>

          <p>
            This application consists of two parts:
            <ol>
              <li>The completed online application form. The link to this form can be found at the bottom of this page.</li>
              <li>
                Official school transcripts that must include
                2021/2022 final marks. These are available from the registrar or guidance
                department of your school. Make sure the 2022 final marks and courses are
                included.
                <p><strong>NOTE TO HIGH SCHOOL STUDENTS:</strong></p>
                <p>
                  Please send transcripts
                  with your final marks and indicating that you have graduated High School
                  June 2022. Arrange for your school or university to mail them directly to the
                  Academic Award application intake office when they are complete. Have
                  your transcripts mailed to:
                </p>
                <p>
                  <strong>
                    <div style={{display: 'block'}}>Maritimes & Northeast Pipeline Academic Awards</div>
                    <div style={{display: 'block'}}>Suite 170</div>
                    <div style={{display: 'block'}}>527 Dundonald Street</div>
                    <div style={{display: 'block'}}>Fredericton, NB</div>
                    <div style={{display: 'block'}}>E3B 1X5</div>
                  </strong>
                </p>
              </li>
            </ol>
          </p>

        <p>
        This is the only Application form accepted for the 2022 Academic Awards.
        Any other application forms, or delivery of forms to other locations,
        disqualifies the application. It is the responsibility of the applicant to make
        sure that official transcripts are mailed to the academic award intake office.
        </p>
        <p>
          <strong>APPLICANTS WILL NOT BE NOTIFIED IF THEIR APPLICATION IS INCOMPLETE.</strong>
        </p>
          <p style={{wordWrap: 'break-word'}}>
            <strong>Apply here: <a rel="noreferrer" target="_blank" href="https://form.jotform.com/221324019086247">https://form.jotform.com/221324019086247</a></strong>
          </p>
      </div>
    </div>
  );
}

export default App;
